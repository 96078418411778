<template>
  <div v-loading.fullscreen.lock="loading">
    <ProcessTable
      ref="processTableRef"
      type="contactsUnnamed"
      :height="tableHeight"
      group-name="type"
      :group-capitalize="true"
    />
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from '@/stores/modules/global'
import ProcessTable from '../components/ProcessTable.vue'
import { useReportsTableConfigStore } from '@/stores/modules/reports/tableConfig'

const route = useRoute()
const loading = ref(false)
const processTableRef = ref()
const globalStore = useGlobalStore()
const tableConfigStore = useReportsTableConfigStore()

const tableHeight = computed(() => {
  return globalStore.showUpGradeInfo ? `calc(100vh - 179px)` : `calc(100vh - 148px)`
})

watch(
  route,
  async (newValue) => {
    loading.value = true
    if (newValue.name === 'reports-contacts-unnamed') {
      tableConfigStore.processTableConfig = []
      await tableConfigStore.getProcessTableConfig('contactsUnnamed')
    }
    setTimeout(() => {
      loading.value = false
    }, 300)
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss" scoped></style>
